import { useQuery } from "react-query"

export const AUTH_URL = process.env.GATSBY_LIGONIER_AUTH_URL

const ligonierAuthorize = async () => {
  const response = await fetch(`${AUTH_URL}/api/silent`, {
    credentials: "include"
  })

  if (response.status !== 200) {
    const errorMessage = await response.text()

    throw new Error(errorMessage)
  }

  return await response.json()
}

export type LigonierAuthResponse = {
  authenticated: boolean
  accessToken?: string
  isMinistryPartner?: boolean
  email?: string
  id?: string
  ligId?: string
  firstName?: string
  lastName?: string
}

const cacheTime = 1000 * 60 * 5 // 5 minutes
const staleTime = 1000 * 30 // 30 seconds

export function useLigonierAuth(fresh = false) {
  const queryResponse = useQuery<LigonierAuthResponse>(
    "ligonierAuth",
    ligonierAuthorize,
    {
      // data will be cached for 5 minutes,
      // when the data is not stale, then the cache,
      // will be used. If the data is stale, then a refetch
      // will be triggered and the cache will be updated.
      cacheTime,
      staleTime: fresh ? 0 : staleTime
    }
  )

  const { data, isLoading } = queryResponse

  return { isLoading, ...(data || {}) }
}
