import React, {
  useState,
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  createContext,
  useContext,
  useRef
} from "react"
import svg4everybody from "svg4everybody"
import "@formatjs/intl-locale/polyfill"
import "@formatjs/intl-getcanonicallocales/polyfill"
import "@formatjs/intl-pluralrules/polyfill"
import "@formatjs/intl-pluralrules/locale-data/en"
import classNames from "classnames"
import "lazysizes"
import { IntlProvider } from "react-intl"
import * as cookie from "cookie"
import { PageProps, PageOptions } from "../../types/page"
import { noop } from "../../core/utils"
import { useCrossReference } from "../../hooks/use-cross-reference"
import { useStore } from "../../features/store/hooks/use-store"
import { locales } from "../../i18n/locales"
import { HeaderWrapper } from "../../features/header/components/header-wrapper"
import { FooterWrapper } from "../../features/navigation/components/footer-wrapper"
import { defaultLocale, messages } from "../../i18n/messages"
import { Metadata } from "../metadata/metadata"
import styles from "./page.module.scss"
import { SearchProvider } from "@features/search/search-provider"
import { useLigonierAuth } from "@utils/ligonierAuth"
import { useSegmentEvent } from "@hooks/use-segment-event"
import { useSegment } from "@hooks/use-segment"

const defaultPageOptions: PageOptions = {
  headerVariant: "white",
  hideSearch: false,
  cartIcon: true,
  isInfoIconShown: false
}

interface ScrollContextValue {
  scrollEnabled: boolean
  setScrollEnabled: (newScrollEnabled: boolean) => void
}

const ScrollContext = createContext<ScrollContextValue>({
  scrollEnabled: true,
  setScrollEnabled: () => void 0
})

export const usePageScroll = () => useContext(ScrollContext)

const setIdentifyCookie = (identified: string) => {
  document.cookie = cookie.serialize("userHasBeenIdentified", identified, {
    path: "/", // The cookie is accessible on the entire site
    maxAge: 60 * 60 * 24 * 180, // 180 days in seconds
    secure: process.env.NODE_ENV === "production", // Use true if your site is served over HTTPS
    sameSite: "strict" // Adjust as needed
  })
}

export function Page<Q = undefined, T = undefined>(
  props: PropsWithChildren<PageProps<Q, T>>
): JSX.Element {
  const {
    children,
    location,
    metadata = {},
    includeMetadata = true,
    pageOptions: incomingPageOptions = {},
    fullHeight = true,
    isInfoIconShown = false,
    yearEndContent
  } = props
  const {
    headerVariant,
    headerVideoOptions,
    hideSearch,
    breadcrumbs,
    defaultSearchIndex,
    allowedIndexes,
    cartIcon,
    heroImageSrc
  } = {
    ...defaultPageOptions,
    ...incomingPageOptions
  }

  const [scrollEnabled, setScrollEnabled] = useState(true)

  const scrollContextValue = { scrollEnabled, setScrollEnabled }

  const wrapperRef = useRef<HTMLDivElement>(null)

  let scrollBarGap = 0

  if (wrapperRef.current && typeof window !== "undefined") {
    scrollBarGap = window.innerWidth - wrapperRef.current.clientWidth
  }

  // Move LiveChat widget when scroll is disabled to compensate for
  // scroll bar gap padding (since it's position: fixed)
  useLayoutEffect(() => {
    const chatWidget: HTMLElement | null = document.querySelector(
      "#chat-widget-container"
    )
    const rightSpace = scrollEnabled ? 0 : scrollBarGap

    if (chatWidget) chatWidget.style.right = `${rightSpace}px`
  }, [scrollEnabled, scrollBarGap])

  const { path } = locales["en-US"]

  const pathname = location.pathname.replace(`/${path}`, "/")

  const pageSearchIndexId = defaultSearchIndex || "featured"

  const { updateLocation } = useStore()

  useSegment()

  const user = useLigonierAuth()
  const { identify } = useSegmentEvent()

  useEffect(() => {
    if (user.isLoading) return
    const cookies = cookie.parse(document.cookie)
    const hasBeenIdentifiedCookie = cookies["userHasBeenIdentified"]

    if (hasBeenIdentifiedCookie !== undefined) {
      if (user.authenticated === false) {
        document.cookie = cookie.serialize("userHasBeenIdentified", "", {
          path: "/",
          maxAge: -1
        })

        return
      }

      let hasBeenIdentified = false

      try {
        hasBeenIdentified = JSON.parse(hasBeenIdentifiedCookie)
      } catch (error) {
        console.error("Failed to parse 'userHasBeenIdentified' cookie:", error)
      }

      if (!hasBeenIdentified) {
        const userData = {
          id: user.ligId || "",
          email: user.email || "",
          firstName: user.firstName || "",
          lastName: user.lastName || ""
        }

        identify(userData)

        setIdentifyCookie("true")
      }
    } else {
      setIdentifyCookie("false")
    }
  }, [user, identify])

  useCrossReference()

  const isHomePage = headerVariant === null
  const menuHidden = [null, "video"].includes(headerVariant)

  useEffect(() => {
    svg4everybody()
  }, [])

  useEffect(() => {
    updateLocation(location)
  }, [location, updateLocation])

  return (
    <ScrollContext.Provider value={scrollContextValue}>
      <IntlProvider
        defaultLocale={defaultLocale}
        locale="en-US"
        messages={{
          ...messages["en-US"]
        }}
        onError={noop}
      >
        <div className={styles.upperScreen} />
        <div
          ref={wrapperRef}
          style={{ ...(!scrollEnabled && { paddingRight: scrollBarGap }) }}
          className={classNames(
            styles.page,
            menuHidden && styles.noMenu,
            fullHeight && styles.fullHeight,
            !scrollEnabled && styles.disableScroll,
            (headerVariant === "Image" || headerVariant === "Video") &&
              styles.noTopPadding
          )}
        >
          {includeMetadata && metadata && (
            <Metadata {...metadata} lang="en-US" />
          )}
          <HeaderWrapper
            isHomePage={isHomePage}
            variant={isHomePage ? undefined : headerVariant!}
            videoOptions={headerVideoOptions}
            hideSearch={hideSearch}
            cartIcon={cartIcon}
            isInfoIconShown={isInfoIconShown}
            location={location}
            pageSearchIndexId={pageSearchIndexId}
            yearEndContent={yearEndContent}
            imageSrc={heroImageSrc}
          />
          <SearchProvider
            location={location as Location}
            searchIndexId={pageSearchIndexId as string}
            allowedIndexes={allowedIndexes}
          >
            <section role="main" className={styles.content}>
              {children}
            </section>
          </SearchProvider>
          <FooterWrapper basePath={pathname} breadcrumbs={breadcrumbs} />
        </div>
      </IntlProvider>
    </ScrollContext.Provider>
  )
}
